import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { AppBar, Box, Button, Chip, ChipProps, Toolbar, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { styled } from "@mui/material/styles";
import { GridColumnGroupingModel, GridRenderCellParams } from '@mui/x-data-grid/models';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SyncIcon from '@mui/icons-material/Sync';

import { Amplify } from 'aws-amplify';
import AWS from 'aws-sdk';

import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsExports from '../aws-exports';
Amplify.configure(awsExports);

/*
 * Configure the SDK to use anonymous identity 
 */
AWS.config.update({
  region: awsExports.aws_cognito_region,
  credentials: new AWS.Credentials({
    accessKeyId: "AKIAZG3XRUZGPZN5Z3F7",
    secretAccessKey: "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"
  })
});

const s3 = new AWS.S3();


interface Props {
  signOut: any,
  user: any
}

async function retrieveFileByPrefix(): Promise<JSON[] | null> {
  try {
    const bucketName = 'metricstreams-kvs-status-qrcnglfx';

    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hour = String(now.getUTCHours()).padStart(2, '0');
  
    const prefix = `${year}/${month}/${day}/${hour}/MetricStreams-KVS-Status-0DlFtCDC-1-${year}-${month}-${day}-${hour}`;

    const objects = await s3.listObjectsV2({ Bucket: bucketName, Prefix: prefix }).promise();

    if (objects.Contents?.length) {
      // If there are matching objects, retrieve the contents of the last one
      const object = objects.Contents[objects.Contents?.length-1];
      const file = await s3.getObject({ Bucket: bucketName, Key: object.Key! }).promise();
      return await readFileContents(new Blob([file.Body as BlobPart]));
    } else {
      // If there are no matching objects, return null
      return null;
    }
  } catch (error) {
    console.error('Error retrieving file:', error);
    return null;
  }
}

function readFileContents(input: Blob): Promise<JSON[]> {
  return new Promise<JSON[]>((resolve, reject) => {
    if (!input) {
      resolve([]);
      return;
    }

    const reader = new FileReader();
    const lines: JSON[] = [];

    reader.onload = (event) => {
      const contents = event.target?.result;
      if (contents) {
        const linesArray = (contents as string).split('\n');
        linesArray.forEach((line) => {
          if (line.trim() !== '') {
            lines.push(JSON.parse(line.trim()));
          }
        });
        resolve(lines);
      }
    };

    reader.onerror = (event) => {
      reject(event.target?.error);
    };

    if (typeof input === 'string') {
      reader.readAsText(new Blob([input]));
    } else {
      reader.readAsText(input);
    }
  });
}

function getCamerasChipProps(params: GridRenderCellParams): ChipProps {
  var val = params.value.split("/")
  var percentage = (parseInt(val[0]) / parseInt(val[1])) * 100

  switch(true) {
    case (percentage >= 90):
      return {
        icon: <CheckCircleOutlineOutlinedIcon/>,
        label: params.value,
        color: "success"
      };
    case (percentage < 90 && percentage >= 50):
      return {
        icon: <ErrorOutlineOutlinedIcon/>,
        label: params.value,
        color: "warning"
      };
    default:
      return {
        icon: <ErrorOutlineOutlinedIcon/>,
        label: params.value,
        color: "error"
      };
  }

}

function getVPNChipProps(params: GridRenderCellParams): ChipProps {
  switch(params.value) {
    case "OFFLINE":
      return {
        icon: <ErrorOutlineOutlinedIcon/>,
        label: "Offline",
        color: "error"
      };
    case "ONLINE":
      return {
        icon: <CheckCircleOutlineOutlinedIcon/>,
        label: "Online",
        color: "success",
        // onClick: () => navigator.clipboard.writeText('ssh -p 22 hellometer@pc.3.hellometer.openvpn.com')
      };
    case "UNKNOWN":
      return {
        icon: <HelpOutlineIcon/>,
        label: "Unknown",
        color: "default"
      };
    default:
      return {
        icon: <SyncIcon/>,
        label: "Loading",
        color: "secondary"
      };
  }
}

function getDetectionChipProps(params: GridRenderCellParams): ChipProps {
  const ONE_HOUR = 60 * 60 * 1000
  const TWO_HOUR = 2 * ONE_HOUR
  var now = Date.now()

  switch(params.value) {
    case "LOADING":
      return {
        icon: <SyncIcon/>,
        label: "Loading",
        color: "secondary"
      };
    default:
      if (params.value.getFullYear() <= 1970) {
        return {
          icon: <HelpOutlineIcon/>,
          label: "Unknown",
          color: "default"
        };
      }
      if((now - params.value) < ONE_HOUR) {
        return {
          icon: <CheckCircleOutlineOutlinedIcon/>,
          label: timeDifference(now, params.value),
          color: "success"
        };
      } else if((now - params.value) < TWO_HOUR) {
        return {
          icon: <ErrorOutlineOutlinedIcon/>,
          label: timeDifference(now, params.value),
          color: "warning"
        };
      } else {
        return {
          icon: <ErrorOutlineOutlinedIcon/>,
          label: timeDifference(now, params.value),
          color: "error"
        };
      }
  }
}

const locationsReducer = (locations: any[], action: any) => {
  switch(action.type) {
    case 'init':
      return action.data;
    case 'add':
      return [...locations, action.data];
    case 'changed':
      return locations.map(l => {
        if (l.id === action.index) {
          l[action.key] = action.value;
        }
        return l;
      });
    case 'add_camera':
      return locations.map(l => {
        if (l.id === action.index) {
          var val = l["cameras"].split("/")
          l["cameras"] = `${parseInt(val[0]) + 1} / ${parseInt(val[1])}`
        }
        return l;
      });
      case 'add_all_cameras':
        return locations.map(l => {
          if (l.id === action.index) {
            var val = l["cameras"].split("/")
            l["cameras"] = `${action.value} / ${parseInt(val[1])}`
          }
          return l;
        });
    default:
      return locations;
  }
}

function timeDifference(current: number, previous: number) {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return '< 1 hour ago';   
    } else if (elapsed < msPerHour) {
      return '< 1 hour ago';   
    } else if (elapsed < msPerDay ) {
      const result = Math.round(elapsed/msPerHour)
      return result + (result > 1 ? ' hours' : ' hour') + ' ago';   
    } else if (elapsed < msPerMonth) {
      const result = Math.round(elapsed/msPerDay)
      return result + (result > 1 ? ' days' : ' day') + ' ago';   
    } else if (elapsed < msPerYear) {
      const result = Math.round(elapsed/msPerMonth)
      return result + (result > 1 ? ' months' : ' month') + ' ago';   
    } else {
      const result = Math.round(elapsed/msPerYear)
      return result + (result > 1 ? ' years' : ' year') + ' ago';   
    }
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip placement='right' {...props} classes={{ popper: className }} arrow/>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 330,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}));

function MyDataGrid(props:Props) {
    const [locations, dispatch] = React.useReducer(locationsReducer, []);
    const columns: GridColDef[] = [
      { 
        field: 'location_id', 
        headerName: 'ID', 
        width: 100,
        renderCell: (params) => {
          return <Tooltip placement='right' title={`ssh -p 22 hellometer@pc.${params.value}.hellometer.openvpn.com`} arrow>
                  <Button onClick={() => {navigator.clipboard.writeText(`ssh -p 22 hellometer@pc.${params.value}.hellometer.openvpn.com`)}}>
                    {params.value}
                  </Button>
                </Tooltip>;
        } 
      },
      { field: 'display_name', headerName: 'Display Name', width: 150 },
      { field: 'franchise', headerName: 'Franchise', width: 150 },
      { 
          field: 'status', 
          headerName: 'VPN Status',
          width: 150, 
          renderCell: (params) => {
              return <Chip {...getVPNChipProps(params)} />;
          } 
      },
      { 
        field: 'last_detection', 
        headerName: 'Lobby',
        type: 'dateTime',
        sortingOrder: ['desc', 'asc'],
        valueGetter: ({ value }) => { switch(value) { default: return new Date(value); case "LOADING": return "LOADING"; case "UNKNOWN": return new Date('1970-01-01'); }},
        width: 200,
        renderCell: (params) => {
          return <Chip {...getDetectionChipProps(params)} />;
        } 
      },
      { 
        field: 'last_detection_dt', 
        headerName: 'DT',
        type: 'dateTime',
        sortingOrder: ['desc', 'asc'],
        valueGetter: ({ value }) => { switch(value) { default: return new Date(value); case "LOADING": return "LOADING"; case "UNKNOWN": return new Date('1970-01-01'); }},
        width: 200,
        renderCell: (params) => {
          return <Chip {...getDetectionChipProps(params)} />;
        } 
      },
      { 
        field: 'cameras', 
        headerName: 'Cameras',
        width: 150,
        renderCell: (params) => {
          return <Chip {...getCamerasChipProps(params)} />;
        } 
      },
      { 
        field: 'links', 
        headerName: 'Links', 
        width: 100,
        renderCell: (params) => {
          return <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">External links for location {params.value}</Typography>
                        <ul>
                          <li>
                            <a href={"http://pc." + params.value + ".hellometer.openvpn.com:3000"} target="_blank" rel="noreferrer">
                              Recovery server
                            </a>
                          </li>
                          <li>
                            <a href={"http://pc." + params.value + ".hellometer.openvpn.com:5005"} target="_blank" rel="noreferrer">
                              DT Monitor
                            </a>
                          </li>
                          <li>
                            <a href={"http://pc." + params.value + ".hellometer.openvpn.com:8083"} target="_blank" rel="noreferrer">
                              Video Monitor
                            </a>
                          </li>
                        </ul>
  
                      </React.Fragment>
                    }
                  >
                    <Button>View</Button>
                  </HtmlTooltip>;
        }
      },
  ];
  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'Last Detection',
      children: [
        { field: 'last_detection' }, 
        { field: 'last_detection_dt' }
      ],
    }
  ];

    React.useEffect (() => {
      retrieveFileByPrefix()
      .then((fileContents) => fetchLocations(fileContents??[]))
      .catch((error) => {
        console.error('Error retrieving file:', error);
      });
    }, []);

    const fetchLocations = (fileContents: any[]) => {
      fetch("https://api-iaily4lvlq-uc.a.run.app/location/getAllLocations")
        .then((response) => response.json())  
        .then((locs) => { 
          var locationsObjects: any[] = [];
          locs.forEach((loc: any, index: number) => {
              var totalCameras = 0;

              loc.devices.forEach((dev: any) => {
                dev.cameras.forEach((cam: any, camIndex: number) => {
                  totalCameras++
                })
              })

              locationsObjects.push({
                  id: index, 
                  location_id: loc.id, 
                  display_name: loc.display_name ?? loc.address,
                  franchise: loc.franchise.name,
                  status: "LOADING",
                  last_detection: "LOADING",
                  last_detection_dt: "LOADING",
                  cameras: loc.franchise.name === 'Grupo Areas' ? 
                            `${totalCameras} / ${totalCameras}` : 
                            `0 / ${totalCameras}`,
                  links: loc.id
              })
          }); 
          dispatch({type: 'init', data: locationsObjects})
          fetchCameras(locs, fileContents)
          fetchDetections(locationsObjects)
          fetchOpenVPNConnectors(locationsObjects)
        });
    }

    const handleUpdateLocation = (index: number, key: string, value: any) => {
        dispatch({type: 'changed', index: index, key: key, value: value});
    }

    const fetchCameras = (locationsObjects: any[], cameraMetrics: any[]) => {
      locationsObjects.forEach((loc, index) => {
        const filteredMetric = cameraMetrics.filter((camera) => {
          return camera["metric_name"] === "PutMedia.IncomingBytes" 
          && camera["dimensions"]["StreamName"] 
          && camera["dimensions"]["StreamName"].startsWith(`${loc.id}_`)
          && camera["value"]["min"] > 0
        })
        if (filteredMetric.length > 0) {
          dispatch({type: 'add_all_cameras', index: index, value: filteredMetric.length})
        }
        
      });
    }

    const fetchDetections = (locationsObjects: any[]) => {
      fetch("https://api-iaily4lvlq-uc.a.run.app/detection/get_last_all_locations")
        .then((response) => response.json())  
        .then((dets) => { 
          locationsObjects.forEach((loc, index) => {
            let findIndex = dets.findIndex((det: any) => det.location_id === loc.location_id && det.is_drive_through === false)
            let findIndexDt = dets.findIndex((det: any) => det.location_id === loc.location_id && det.is_drive_through === true)
            handleUpdateLocation(index, "last_detection", findIndex !== -1 ? dets[findIndex].hour : "UNKNOWN")
            handleUpdateLocation(index, "last_detection_dt", findIndexDt !== -1 ? dets[findIndexDt].hour : "UNKNOWN")
          })
        });
    }

    const fetchOpenVPNConnectors = (locationsObjects: any[]) => {
      fetch('https://o0avnmoiuj.execute-api.us-west-2.amazonaws.com/Prod/internal/openVpnStatus')
        .then(res => res.json())
        .then(openVpnData => {
          locationsObjects.forEach((loc, index) => {
            if (loc.franchise === 'Grupo Areas') {
              dispatch({type: 'changed', index: index, key: "status", value: "ONLINE"})
            } else {
              let findIndex = openVpnData.findIndex((conn: any) => parseInt(conn.name.split('L')[1]) === loc.location_id)
              if (findIndex !== -1) {
                dispatch({type: 'changed', index: index, key: "status", value: openVpnData[findIndex].connectionStatus})
              } else {
                dispatch({type: 'changed', index: index, key: "status", value: "UNKNOWN"});
              }
            }
          })
        })
    }

    return (
      <Box sx={{ width: '100%' }} >
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
              Hellometer Status
            </Typography>
            <Button onClick={props.signOut} color="inherit">LogOut</Button>
          </Toolbar>
        </AppBar>
          <div style={{ display: 'flex', height: '100%' }}>
              <div style={{ flexGrow: 1 }}>
                  <DataGrid 
                      autoHeight 
                      initialState={{
                          pagination: {
                              pageSize: 50,
                          },
                          sorting: {
                              sortModel: [{ field: 'last_detection', sort: 'desc' }],
                          },
                      }}
                      loading={locations.length === 0} 
                      rows={locations} 
                      experimentalFeatures={{ columnGrouping: true }}
                      columns={columns} 
                      columnGroupingModel={columnGroupingModel}
                  />
              </div>
          </div>
      </Box>
    );
}

export default withAuthenticator(MyDataGrid)